import { template as template_5c95c66ae23944cb881ccd54f92028dc } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
export default template_5c95c66ae23944cb881ccd54f92028dc(`
  <nav class='navbar session-details__controls-navbar-tabs'>
    <LinkTo @route='authenticated.sessions.details.parameters' class='navbar-item'>
      {{t 'pages.sessions.detail.tabs.details'}}
    </LinkTo>
    <LinkTo @route='authenticated.sessions.details.certification-candidates' class='navbar-item'>
      {{t 'common.sessions.candidates'}}
      {{@certificationCandidatesCount}}
    </LinkTo>
  </nav>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
