import { template as template_9cb764989ff2471992fa6b04b785fb1e } from "@ember/template-compiler";
import { t } from 'ember-intl';
export default template_9cb764989ff2471992fa6b04b785fb1e(`
  <header>
    <img src='/illu-espace-surveillant.svg' alt='' />
    <h1>{{t 'pages.session-supervising.login.form.title'}}</h1>
    <h2>{{t 'pages.session-supervising.login.form.sub-title'}}</h2>
    <p>{{t 'common.form-errors.mandatory-all-fields'}}</p>

    {{#if @errorMessage}}
      <p id='login-session-supervisor-form-error-message' class='error-message'>
        {{@errorMessage}}
      </p>
    {{/if}}
  </header>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
