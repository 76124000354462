import { template as template_5fe7c9d7bf824309bf28be4dfab84879 } from "@ember/template-compiler";
export default template_5fe7c9d7bf824309bf28be4dfab84879(`
  <li class='row'>
    <span class='row__label'>
      {{@label}}
    </span>
    <span class='row__value'>
      {{@value}}
    </span>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
