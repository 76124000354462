import { template as template_81fada3c79134052a2e5d9d915bb2464 } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
export default template_81fada3c79134052a2e5d9d915bb2464(`
  <nav class='navbar session-details__controls-navbar-tabs'>
    <LinkTo @route='authenticated.sessions.details.parameters' class='navbar-item'>
      {{t 'pages.sessions.detail.tabs.details'}}
    </LinkTo>
    <LinkTo @route='authenticated.sessions.details.certification-candidates' class='navbar-item'>
      {{t 'common.sessions.candidates'}}
      {{@certificationCandidatesCount}}
    </LinkTo>
  </nav>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
